// import Swup from "swup";
// import SwupPreloadPlugin from "@swup/preload-plugin";
// import SwupBodyClassPlugin from "@swup/body-class-plugin";
// import SwupScrollPlugin from "@swup/scroll-plugin";
import WebFont from "webfontloader";
import $ from "jquery";
import "lazysizes";
import "infinite-scroll";
// import 'lazysizes/plugins/native-loading/ls.native-loading';
import Flickity from "flickity";
import "flickity-fade";
import imagesLoaded from "imagesloaded";
import { vimeoPlayers } from "./components/scroll-video.js";
// import "./plugins/jquery.fitvids";
import fitVids from "./components/fitvids.js";

let state = {
	navOpen: false,
	logoHidden: false,
	logoReverse: false,
	flkty: {},
	infScroll: {},
};

$(() => {
	const isPhone = () => {
		return window.screen.width < 768 && navigator.maxTouchPoints === 1;
	};

	const initSite = () => {
		document.querySelector(".no-js").classList.remove("no-js");
		WebFont.load({
			timeout: 3000,
			custom: {
				families: ["Mrs Eaves", "Avenir Next"],
			},
			active: () => {
				sessionStorage.setItem("fonts", true);
			},
		});

		const navbar = document.querySelector("#modal-nav");

		$(".navbar-burger").on("click", () => {
			state.navOpen = !state.navOpen;
			$("body").toggleClass("nav-active");
			if (state.navOpen) {
				$(".navbar-burger").attr("aria-expanded", "true");
			} else {
				$(".navbar-burger").attr("aria-expanded", "false");
			}
		});
	};

	/* end initSite */

	const pageLoad = () => {
		// Scrolly videos
		if (document.querySelector(".scroll-video")) {
			vimeoPlayers(
				".scroll-video",
				false,
				{
					width: 1216,
				},
				{
					threshold: 0.7,
				}
			);
		}

		if (document.querySelector(".video-tn")) {
			vimeoPlayers(".video-tn", false, {}, {});
		}

		if (document.querySelector(".wp-block-embed-vimeo")) {
			fitVids({
				customSelector: ".wp-block-embed-vimeo",
			});
		}

		if (document.querySelector(".carousel")) {
			const slider = document.querySelector(".carousel");
			if (document.querySelectorAll(".carousel .slide").length > 1) {
				const imgLoad = imagesLoaded(slider, () => {
					const options = {
						cellSelector: ".slide",
						resize: true,
						fade: true,
						prevNextButtons: false,
						pageDots: true,
						autoPlay: 5000,
						draggable: isPhone() ? true : false,
						setGallerySize: false,
						pauseAutoPlayOnHover: false,
					};
					state.flkty = new Flickity(slider, options);
					slider.classList.remove("loading");
				});

				slider.addEventListener(
					"click",
					() => {
						if (state.flkty) {
							state.flkty.next(true);
						}
					},
					{ passive: true }
				);
			}
		}
	};

	/* end pageLoad */

	// const pageUnLoad = () => {
	// 	if (document.querySelector(".carousel")) {
	// 		state.flkty.destroy();
	// 	}
	// };

	// const swup = new Swup({
	// 	cache: true,
	// 	elements: ["#swup"],
	// 	plugins: [
	// 		new SwupPreloadPlugin(),
	// 		new SwupBodyClassPlugin(),
	// 		new SwupScrollPlugin({
	// 			doScrollingRightAway: false,
	// 			animateScroll: false,
	// 		}),
	// 	],
	// });

	initSite();
	pageLoad();

	// swup.on("animationOutDone", () => {
	// 	state.navOpen = false;
	// 	$("body").removeClass("nav-active");
	// 	$(".navbar-burger").attr("aria-expanded", "false");
	// });

	// swup.on("contentReplaced", () => {
	// 	pageLoad();
	// });

	// swup.on("willReplaceContent", () => {
	// 	pageUnLoad();
	// });
});
