import Player from "@vimeo/player";
import fitVids from "./fitvids";

export function vimeoPlayers(
	selector,
	autoPlay = false,
	playerOptions = {},
	observerOptions = {}
) {
	const defaultObserverOptions = {
		root: null,
		rootMargin: "0px",
		threshold: 0.5,
	};

	const options = {
		...defaultObserverOptions,
		...observerOptions,
	};

	const players = {};

	const observer = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			const video_id = entry.target.dataset.id;
			const player = players[video_id];
			if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
				if (player && !autoPlay) {
					player.play();
				}
			} else {
				if (player && !autoPlay) {
					player.pause();
				}
			}
		});
	}, options);

	document.querySelectorAll(selector).forEach((el) => {
		const video_id = el.dataset.id;
		const player = new Player(el.id, {
			id: video_id,
			background: true,
			width: 1280,
			...playerOptions,
		});
		if (!autoPlay) {
			player.pause();
		}
		player.on("loaded", function () {
			fitVids({
				customSelector: "#" + el.id,
			});
			setTimeout(() => {
				el.classList.remove("opacity-0");
			}, 500);
		});
		players[video_id] = player;
		observer.observe(el);
	});
}
