export default function fitVids(options) {
	var settings = {
		customSelector: null,
		ignore: null,
	};

	if (!document.getElementById("fit-vids-style")) {
		var head = document.head || document.getElementsByTagName("head")[0];
		var css =
			".fluid-width-video-wrapper{width:100%;position:relative;padding:0;}.fluid-width-video-wrapper iframe,.fluid-width-video-wrapper object,.fluid-width-video-wrapper embed {position:absolute;top:0;left:0;width:100%;height:100%;}";
		var div = document.createElement("div");
		div.innerHTML =
			'<p>x</p><style id="fit-vids-style">' + css + "</style>";
		head.appendChild(div.childNodes[1]);
	}

	if (options) {
		Object.assign(settings, options);
	}

	var selectors = [
		'iframe[src*="player.vimeo.com"]',
		'iframe[src*="youtube.com"]',
		'iframe[src*="youtube-nocookie.com"]',
		'iframe[src*="kickstarter.com"][src*="video.html"]',
		"object",
		"embed",
	];

	var ignoreList = ".fitvidsignore";

	if (settings.ignore) {
		ignoreList = ignoreList + ", " + settings.ignore;
	}

	var container = settings.customSelector
		? document.querySelector(settings.customSelector)
		: document;

	if (container === null) {
		return;
	}

	var allVideos = container.querySelectorAll(selectors.join(","));

	var fitVidsCount = 0;

	Array.prototype.forEach.call(allVideos, function (video) {
		if (video.closest(ignoreList)) {
			return;
		}
		if (
			(video.tagName.toLowerCase() === "embed" &&
				video.parentElement.tagName.toLowerCase() === "object") ||
			video.parentElement.classList.contains("fluid-width-video-wrapper")
		) {
			return;
		}
		if (!video.getAttribute("height") && !video.getAttribute("width")) {
			video.setAttribute("height", 9);
			video.setAttribute("width", 16);
		}
		var height =
			video.tagName.toLowerCase() === "object" ||
			!isNaN(parseInt(video.getAttribute("height"), 10))
				? parseInt(video.getAttribute("height"), 10)
				: video.offsetHeight;
		var width = !isNaN(parseInt(video.getAttribute("width"), 10))
			? parseInt(video.getAttribute("width"), 10)
			: video.offsetWidth;
		var aspectRatio = height / width;

		if (!video.getAttribute("name")) {
			var videoName = "fitvid" + fitVidsCount;
			video.setAttribute("name", videoName);
			fitVidsCount++;
		}

		var wrapper = document.createElement("div");
		wrapper.className = "fluid-width-video-wrapper";
		wrapper.style.paddingTop = aspectRatio * 100 + "%";

		video.parentElement.insertBefore(wrapper, video);
		wrapper.appendChild(video);

		video.removeAttribute("height");
		video.removeAttribute("width");
	});
}
